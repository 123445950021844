






























import Vue from "vue"

import { mapGetters, mapState, mapActions } from "vuex"
import ColorPalette from "./ColorPalette.vue"
import BrushSize from "./BrushSize.vue"

export default Vue.extend({
  name: "DrawingPicker",
  components: { ColorPalette, BrushSize },
  props: {
    decorated: {
      type: Boolean,
      default: true
    },
    sizeResetable: {
      type: Boolean,
      default: true
    },
    clearTeam: {
      type: Boolean,
      default: true
    },
    sizes: {
      type: [Array, Boolean],
      default: () => [1, 4, 8, 16, 40]
    },
    brushSize: {
      type: Number,
      default: undefined
    },
    erazerSzize: {
      type: Number,
      default: 50
    }
  },
  computed: {
    ...mapGetters("auth", ["isHost", "user"]),
    ...mapState("drawing", ["lineWidth", "lineColorRgb", "pen"]),

    toolSize: {
      get() {
        return this.lineWidth
      },
      set(lineWidth) {
        this.setLineWidth({ lineWidth })
      }
    },
    selectedColor: {
      get() {
        return this.lineColorRgb
      },
      set([r, g, b]) {
        this.setPenState(true)
        this.setPenColor({ color: { rgba: { r, g, b, a: 1 } } })
        if (!this.sizes) {
          this.setLineWidth({ lineWidth: this.brushSize || 4 })
        }
      }
    }
  },
  mounted() {
    if (this.brushSize) {
      this.setLineWidth({ lineWidth: this.brushSize })
    }
  },
  beforeDestroy() {
    if (this.sizeResetable) {
      this.setLineWidth({ lineWidth: 4 })
    }
  },
  methods: {
    ...mapActions("drawing", [
      "setPenState",
      "setPenColor",
      "setLineWidth",
      "clearOneTeam"
    ]),
    setToEraser() {
      // deselect color
      this.setPenColor({
        color: {
          rgba: {
            r: "",
            g: "",
            b: "",
            a: ""
          }
        }
      })

      this.setPenState(false)

      if (!this.sizes) {
        this.setLineWidth({ lineWidth: this.erazerSzize })
      }
    },
    onClearCurrentTeam() {
      this.clearOneTeam({
        teamID: this.$store.getters["group/globalTeamID"]
      })
    }
  }
})
