var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "drawing-picker",
      class: { "drawing-picker--decorated": _vm.decorated },
    },
    [
      _c("ColorPalette", {
        staticClass: "mb-3",
        model: {
          value: _vm.selectedColor,
          callback: function ($$v) {
            _vm.selectedColor = $$v
          },
          expression: "selectedColor",
        },
      }),
      _c("div", { staticClass: "drawing-picker__tools" }, [
        _c(
          "div",
          {
            staticClass: "drawing-picker__btn",
            class: { "drawing-picker__btn--active": !_vm.pen },
            on: { click: _vm.setToEraser },
          },
          [
            _c("SvgIcon", {
              attrs: { name: "eraser", width: "18", height: "18" },
            }),
            _vm._v(" Erase "),
          ],
          1
        ),
      ]),
      _vm.sizes
        ? _c("BrushSize", {
            attrs: { sizes: _vm.sizes },
            model: {
              value: _vm.toolSize,
              callback: function ($$v) {
                _vm.toolSize = $$v
              },
              expression: "toolSize",
            },
          })
        : _vm._e(),
      _vm.isHost && _vm.clearTeam
        ? _c(
            "a",
            {
              staticClass: "absolute bottom-0 left-0 px-2 py-2",
              on: { click: _vm.onClearCurrentTeam },
            },
            [_vm._v(" CLEAR ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }